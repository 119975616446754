import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./packingMaterials.scss";

export default class PackingMaterials extends Component {
  render() {
    return (
      <div className="epoch">
        <div>
          <div className="epoch-title">
            <h1>Defcronyke - Packing Materials</h1>
          </div>

          <Link to="/">
            <img
              src="./images/defcronyke-packing_materials-album-cover.jpg"
              alt="Dark blue sky with a crescent moon showing through some bare tree branches."
            />
          </Link>

          <div className="links">
            <h3>
              <Link to="/">Defcronyke</Link> is a solo electronic music project
              by{" "}
              <a
                href="https://eternalvoid.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                Jeremy Carter
              </a>{" "}
              of the band{" "}
              <a
                href="https://telephonesound.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Telephone Sound
              </a>
              . Based in{" "}
              <a
                href="https://goo.gl/maps/68jtLij9kX72"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ottawa, Ontario, Canada
              </a>
              .
            </h3>

            <h2>Packing Materials, released in 2017:</h2>

            {/* <iframe title="The album Packing Materials by Defcronyke, embedded from Audius." src="https://audius.co/embed/album?id=47038&ownerId=167296&flavor=card" width="100%" height="480" allow="encrypted-media" style={{'border': 'none'}} /> */}
            <iframe
              title="The album Packing Materials by Defcronyke, embedded from YouTube."
              width="560"
              height="315"
              src="https://www.youtube.com/embed/hxZ7GRb7Mf4?list=PLA9w7gX4C1R3-e6ImudVUD74CBcLDXEAa"
              frameBorder="0"
              allowFullScreen
            />
            {/* <div className="choon-player" style={{ border: '0px solid silver', overflow: 'hidden', margin: '15px auto', minWidth: '1550px', marginLeft: '-200px' }}><iframe title="The album Packing Materials by Defcronyke, embedded from Choon." style={{ border: '0px solid silver', marginLeft: '-260px', height: '880px', marginTop: '-50px', width: '100%' }} src="https://choon.co/albums/0qw61pplkqq/packing-materials" scrolling="no"><span style={{ display: 'inline-block', width: '0px', overflow: 'hidden', lineHeight: 0 }} data-mce-type="bookmark" class="mce_SELRES_start">?</span></iframe></div> */}

            <ul>
              <li>
                <a
                  href="https://defcronyke.bandcamp.com/album/packing-materials"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stream or buy on Bandcamp
                </a>
              </li>
              <li>
                <a
                  href="https://play.google.com/store/music/album/Defcronyke_Packing_Materials?id=Bysr4eu2qahjlw2jep6ots74tha"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stream or buy on Google Play Music Store
                </a>
              </li>
              {/* <li><a href="https://play.google.com/store/music/album/Defcronyke_Packing_Materials?id=Bhwmiyixrcgrvbzglbjbuaryfgy" target="_blank" rel="noopener noreferrer">Stream or buy on Google Play Music Store</a></li> */}
              <li>
                <a
                  href="https://itunes.apple.com/us/album/packing-materials/1435672588?app=itunes&ign-mpt=uo%3D4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Buy on iTunes
                </a>
              </li>
              <li>
                <a
                  href="https://www.amazon.com/gp/product/B07H6CDF9J"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Buy on Amazon
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/playlist?list=PLA9w7gX4C1R3-e6ImudVUD74CBcLDXEAa"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stream on YouTube
                </a>
              </li>
              <li>
                <a
                  href="https://soundcloud.com/defcronyke/sets/packing-materials"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stream or download on SoundCloud
                </a>
              </li>
              <li>
                <a
                  href="https://audiomack.com/album/defcronyke/packing-materials"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stream on Audiomack
                </a>
              </li>
              <li>
                <a
                  href="https://open.spotify.com/album/375RXYCHLr1oCQnCRtsFxW"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stream on Spotify
                </a>
              </li>
              <li>
                <a
                  href="https://itunes.apple.com/us/album/packing-materials/1435672588?app=music&ign-mpt=uo%3D4"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stream on Apple Music
                </a>
              </li>
              <li>
                <a
                  href="https://www.deezer.com/en/album/72637412"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stream on Deezer
                </a>
              </li>
              <li>
                <a
                  href="https://tidal.com/browse/album/94779511"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stream on Tidal
                </a>
              </li>
              {/* <li><a href="https://choon.co/albums/0qw61pplkqq/packing-materials" target="_blank" rel="noopener noreferrer">Stream on Choon</a></li> */}
              <li>
                <a
                  href="https://www.kkbox.com/hk/en/album/pkp6el7HP33090F2MgT6009H-index.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stream on KKBox
                </a>
              </li>
              <li>
                <a
                  href="https://archive.org/details/defcronyke-packing_materials"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stream or download on Archive.org
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/defcronykesound"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Follow on Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.patreon.com/telephonesound"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Support Defcronyke on Patreon
                </a>
              </li>
              <li>
                Support Defcronyke by sending an{" "}
                <a
                  href="https://en.wikipedia.org/wiki/Interac_e-Transfer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Interac eTransfer
                </a>{" "}
                or{" "}
                <a
                  href="https://paypal.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PayPal transfer
                </a>{" "}
                to:{" "}
                <a href="mailto:Jeremy@JeremyCarter.ca">
                  Jeremy@JeremyCarter.ca
                </a>
              </li>
              <li>
                Support Defcronyke by sending{" "}
                <a
                  href="https://en.wikipedia.org/wiki/Bitcoin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Bitcoin
                </a>{" "}
                to BTC address:{" "}
                <a href="bitcoin:38JVUzDvP2WSFBHzScRGgBoTYRMpotSVoJ?amount=0.00035">
                  38JVUzDvP2WSFBHzScRGgBoTYRMpotSVoJ
                </a>
              </li>
            </ul>

            <h2>Download the album with Bittorrent:</h2>
            <ul>
              <li>
                <a href="magnet:?xt=urn:btih:7436387f810f2d5f983ff1916939b8679a647909&dn=Defcronyke%20-%20Packing%20Materials%20-%202017%20-%20flac&tr=udp%3A%2F%2Ftracker.coppersurfer.tk%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.open-internet.nl%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.leechers-paradise.org%3A6969%2Fannounce&tr=udp%3A%2F%2Fexodus.desync.com%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.internetwarriors.net%3A1337%2Fannounce&tr=udp%3A%2F%2F9.rarbg.to%3A2710%2Fannounce&tr=udp%3A%2F%2F9.rarbg.me%3A2710%2Fannounce&tr=udp%3A%2F%2Ftracker.opentrackr.org%3A1337%2Fannounce">
                  flac
                </a>
              </li>
              <li>
                <a href="magnet:?xt=urn:btih:ef338bddf4f6a25e3f099ae287168e15401f374a&dn=Defcronyke%20-%20Packing%20Materials%20-%202017%20-%20mp3&tr=udp%3A%2F%2Ftracker.coppersurfer.tk%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.open-internet.nl%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.leechers-paradise.org%3A6969%2Fannounce&tr=udp%3A%2F%2Fexodus.desync.com%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.internetwarriors.net%3A1337%2Fannounce&tr=udp%3A%2F%2F9.rarbg.to%3A2710%2Fannounce&tr=udp%3A%2F%2F9.rarbg.me%3A2710%2Fannounce&tr=udp%3A%2F%2Ftracker.opentrackr.org%3A1337%2Fannounce">
                  mp3
                </a>
              </li>
              <li>
                <a href="magnet:?xt=urn:btih:60402b641511a4331aeb049e7500b914f465279f&dn=Defcronyke%20-%20Packing%20Materials%20-%202017%20-%20ogg&tr=udp%3A%2F%2Ftracker.coppersurfer.tk%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.open-internet.nl%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.leechers-paradise.org%3A6969%2Fannounce&tr=udp%3A%2F%2Fexodus.desync.com%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.internetwarriors.net%3A1337%2Fannounce&tr=udp%3A%2F%2F9.rarbg.to%3A2710%2Fannounce&tr=udp%3A%2F%2F9.rarbg.me%3A2710%2Fannounce&tr=udp%3A%2F%2Ftracker.opentrackr.org%3A1337%2Fannounce">
                  ogg
                </a>
              </li>
              <li>
                <a href="magnet:?xt=urn:btih:88ce4df2a5c355392f55e6413158649393b590e9&dn=Defcronyke%20-%20Packing%20Materials%20-%202017%20-%20wav&tr=udp%3A%2F%2Ftracker.coppersurfer.tk%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.open-internet.nl%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.leechers-paradise.org%3A6969%2Fannounce&tr=udp%3A%2F%2Fexodus.desync.com%3A6969%2Fannounce&tr=udp%3A%2F%2Ftracker.internetwarriors.net%3A1337%2Fannounce&tr=udp%3A%2F%2F9.rarbg.to%3A2710%2Fannounce&tr=udp%3A%2F%2F9.rarbg.me%3A2710%2Fannounce&tr=udp%3A%2F%2Ftracker.opentrackr.org%3A1337%2Fannounce">
                  wav
                </a>
              </li>
            </ul>

            <h2>Buy the album with Bitcoin:</h2>

            <div className="bitcoin">
              <ol>
                <li>
                  Send an email to{" "}
                  <a href="mailto:Jeremy@JeremyCarter.ca">
                    Jeremy@JeremyCarter.ca
                  </a>{" "}
                  and give us a heads-up that you&#39;ll be buying the album.
                  You must do this before you send the BTC, for verification
                  purposes. You don&#39;t need to wait for us to respond.
                </li>
                <li>
                  Send 0.00035 BTC to this address:{" "}
                  <a href="bitcoin:38JVUzDvP2WSFBHzScRGgBoTYRMpotSVoJ?amount=0.00035">
                    38JVUzDvP2WSFBHzScRGgBoTYRMpotSVoJ
                  </a>
                </li>
                <li>
                  Email the TX ID of your transaction to{" "}
                  <a href="mailto:Jeremy@JeremyCarter.ca">
                    Jeremy@JeremyCarter.ca
                  </a>
                  , from the same email address you used before.
                </li>
                <li>
                  After we process your order, you will receive a link to a
                  password protected .7z file with the .wav and .flac files in
                  it by email. You can extract the .7z file using{" "}
                  <a
                    href="http://p7zip.sourceforge.net/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    p7zip
                  </a>{" "}
                  on{" "}
                  <a
                    href="https://en.wikipedia.org/wiki/Linux"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Linux
                  </a>{" "}
                  or{" "}
                  <a
                    href="https://www.7-zip.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    7zip
                  </a>{" "}
                  on other platforms.
                </li>
              </ol>
            </div>

            <h3>
              Check out what Defcronyke is working on for the next album. New
              songs get posted to{" "}
              <a
                href="https://audiomack.com/artist/defcronyke"
                target="_blank"
                rel="noopener noreferrer"
              >
                Audiomack
              </a>{" "}
              and{" "}
              <a
                href="https://soundcloud.com/defcronyke"
                target="_blank"
                rel="noopener noreferrer"
              >
                SoundCloud
              </a>{" "}
              while working on the next album:
            </h3>

            <iframe
              title="All music by Defcronyke, embedded from Audiomack"
              src="https://audiomack.com/embed/playlist/defcronyke/all?background=1"
              scrolling="no"
              width="100%"
              height="400"
              scrollbars="no"
              frameBorder="0"
            />
            {/* <iframe width="100%" height="450" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/496325133&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"/> */}

            <h4>
              If you like this, check out{" "}
              <a
                href="https://eternalvoid.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                Jeremy
              </a>
              &#39;s band with{" "}
              <a
                href="https://prettyweird.ca"
                target="_blank"
                rel="noopener noreferrer"
              >
                Daphne Volante
              </a>
              , Telephone Sound:{" "}
              <a
                href="https://telephonesound.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                telephonesound.com
              </a>
            </h4>
            <h4>
              The source code for this website is available here:{" "}
              <a
                href="https://github.com/defcronyke/defcronyke"
                target="_blank"
                rel="noopener noreferrer"
              >
                github.com/defcronyke/defcronyke
              </a>
            </h4>
            <h4>
              Part of{" "}
              <a
                href="https://eternalvoid.net"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Eternal Void Network
              </a>
              .
            </h4>

            <br />
            <br />
          </div>
        </div>
      </div>
    );
  }
}
